#root {
  overflow: hidden;
  min-height: 100%;
}
.wrap-all {
  position: relative;
  min-height: 100%;
  width: 100%;
}

/* LAYOUT */
.header {
  z-index: 40;
}
.main {
  z-index: 10;
}
.footer {
  z-index: 20;
}

.row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-30 {
  width: 30%;
  flex-basis: unset;
}
.col-40 {
  width: 40%;
  flex-basis: unset;
}
.col-50 {
  width: 50%;
  flex-basis: unset;
}
.col-60 {
  width: 70%;
  flex-basis: unset;
}
.col-45 {
  width: 45%;
  flex-basis: unset;
}

.cols-to-right {
  justify-content: flex-end;
}

.container {
  position: relative;
  max-width: 1340px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}
.section {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-bottom: 10px solid #ffffff;
}
.section .col,
.section .col-auto {
  padding: 120px 6%;
}

.section .container .col,
.section .container .col-auto {
  padding: 80px 30px;
}

.section p {
  margin-bottom: 0;
  padding-bottom: 28px;
}
.section p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.section-header {
  position: relative;
  height: 100vh;
  min-height: 360px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding-top: 130px;
  padding-bottom: 120px;
}

.section > div,
.section .container,
.section-header .container {
  z-index: 2;
  position: relative;
}

.overlay-gradient-1 {
  color: #ffffff;
}
.overlay-gradient-1:before {
  /*from left to right*/
  z-index: 1;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(48, 42, 42, 1);
  background: -moz-linear-gradient(
    left,
    rgba(48, 42, 42, 1) 0%,
    rgba(48, 42, 42, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(48, 42, 42, 1)),
    color-stop(100%, rgba(48, 42, 42, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(48, 42, 42, 1) 0%,
    rgba(48, 42, 42, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(48, 42, 42, 1) 0%,
    rgba(48, 42, 42, 0) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(48, 42, 42, 1) 0%,
    rgba(48, 42, 42, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(48, 42, 42, 1) 0%,
    rgba(48, 42, 42, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#302a2a', endColorstr='#302a2a', GradientType=1 );
}
.overlay-gradient-2 {
  color: #ffffff;
}
.overlay-gradient-2:before {
  /* full color */
  z-index: 1;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(104, 81, 85, 0.7);
}
.overlay-gradient-3 {
  color: #ffffff;
}
.overlay-gradient-3:before {
  /*from right to left*/
  z-index: 1;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(48, 42, 42, 0);
  background: -moz-linear-gradient(
    left,
    rgba(48, 42, 42, 0) 0%,
    rgba(48, 42, 42, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(48, 42, 42, 0)),
    color-stop(100%, rgba(48, 42, 42, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(48, 42, 42, 0) 0%,
    rgba(48, 42, 42, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(48, 42, 42, 0) 0%,
    rgba(48, 42, 42, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(48, 42, 42, 0) 0%,
    rgba(48, 42, 42, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(48, 42, 42, 0) 0%,
    rgba(48, 42, 42, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#302a2a', endColorstr='#302a2a', GradientType=1 );
}
.overlay-gradient-4 {
  color: #ffffff;
}
.overlay-gradient-4:before {
  /* from bottom to top*/
  z-index: 1;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: rgba(167, 144, 106, 0);
  background: -moz-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(62, 55, 44, 0)),
    color-stop(100%, rgba(62, 55, 44, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7906a', endColorstr='#a7906a', GradientType=0 );
}
.overlay-gradient-5 {
  color: #ffffff;
}
.overlay-gradient-5:before {
  /* from bottom to top*/
  z-index: 1;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: rgba(0, 55, 128, 0);
  background: -moz-linear-gradient(
    top,
    rgba(0, 55, 128, 0) 0%,
    rgba(0, 55, 128, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 55, 128, 0)),
    color-stop(100%, rgba(0, 55, 128, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 55, 128, 0) 0%,
    rgba(0, 55, 128, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 55, 128, 0) 0%,
    rgba(0, 55, 128, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 55, 128, 0) 0%,
    rgba(0, 55, 128, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 55, 128, 0) 0%,
    rgba(0, 55, 128, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003780', endColorstr='#003780', GradientType=0 );
}

.footer-overflay {
  z-index: 1;
  color: #ffffff;
}
.footer-overflay:before {
  z-index: 1;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: rgba(200, 162, 100, 1);
  background: -moz-linear-gradient(
    top,
    rgba(200, 162, 100, 1) 0%,
    rgba(66, 56, 39, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(200, 162, 100, 1)),
    color-stop(100%, rgba(66, 56, 39, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(200, 162, 100, 1) 0%,
    rgba(66, 56, 39, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(200, 162, 100, 1) 0%,
    rgba(66, 56, 39, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(200, 162, 100, 1) 0%,
    rgba(66, 56, 39, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(200, 162, 100, 1) 0%,
    rgba(66, 56, 39, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c8a264', endColorstr='#423827', GradientType=0 );
}

.main {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* end LAYOUT */

/*MAIN*/

.link-more,
.link-more-white {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  min-width: 160px;
  padding: 10px 15px;
  line-height: 1em;
  font-size: 0.8em;
}
.link-more {
  background-color: #c8a264;
  color: #ffffff;
}
.link-more-white {
  background-color: #ffffff;
  color: #6d6158;
}

/*end MAIN*/

/*SLIDERS */
.ril__toolbar li:before {
  display: none;
}

.slider-gallery,
.slider-full,
.slick-slider,
.slick-slider .slick-list,
.slick-slider .slick-track,
.slick-slide,
.slick-slide > div,
.slick-slide > div > div {
  height: 100%;
}

.slick-next,
.slick-prev,
.slick-next:before,
.slick-prev:before {
  z-index: 20;
  display: block;
  width: 60px;
  height: 60px;
}
.slick-next:before,
.slick-prev:before {
  content: " ";
  background-repeat: no-repeat;
  background-position: center center;
  display: block !important;
  z-index: 20;
  background-size: contain;
}
.slick-next {
  right: -5%;
}
.slick-prev {
  left: -5%;
}
.slick-next:before {
  background-image: url("./imgs/arrow-right.png");
}
.slick-prev:before {
  background-image: url("./imgs/arrow-left.png");
}
@media (max-width: 1024px) {
  .slick-next,
  .slick-prev,
  .slick-next:before,
  .slick-prev:before {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 640px) {
  .slick-next {
    right: -15px;
  }
  .slick-prev {
    left: -15px;
  }
}

.slider-gallery {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;
}
.slider-gallery h3 {
  display: none;
}
.slider-gallery .slide {
  padding: 25px;
  margin: auto;
  overflow: hidden;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.slider-gallery .slide img {
  margin: auto;
  width: 100%;
  height: auto;
}
.slider-gallery .slick-center .slide {
  padding: 0;
}
@media (max-width: 900px) {
  .slider-gallery {
    text-align: center;
  }
  .slider-gallery h3 {
    text-align: center;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  .slider-gallery .slide {
    padding: 0;
  }
}

.slider-full .slide-inner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.slider-full .slick-next {
  right: 5%;
}
.slider-full .slick-prev {
  left: 5%;
}
.slider-full .slick-next:before {
  background-image: url("./imgs/arrow-right-white.png");
}
.slider-full .slick-prev:before {
  background-image: url("./imgs/arrow-left-white.png");
}

@media (max-width: 1550px) {
  .slider-full .slick-next {
    right: 0;
  }
  .slider-full .slick-prev {
    left: 0;
  }
}
/*end SLIDERS */

/*MODAL*/
.customModalOverlay {
  padding: 0;
  margin: 0;
}
.customModal {
  min-height: 100%;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.modal {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.modal-content {
  z-index: 2;
  position: absolute;
  display: block;
  margin: auto;
  padding: 2em 4.5em;
  width: 100%;
  min-height: 100%;
  background-color: rgba(167, 144, 106, 0.6);
  color: #ffffff;
}
.modal .bg-blur {
  z-index: 1;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.styles_closeButton__20ID4 {
  /*modal close button */
  z-index: 3;
  position: absolute;
  display: block;
  top: 3em;
  left: 2em;
  width: 27px;
  height: 30px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("./imgs/icon-modal-close.svg");
}
.styles_closeButton__20ID4 svg {
  opacity: 0;
}
@media (max-width: 900px) {
  .modal-content {
    padding: 2em 30px;
  }
  .styles_closeButton__20ID4 {
    left: 15px;
    top: 40px;
  }
}
/*end MODAL */

/* MEDIA */

@media (max-width: 1440px) {
  .section {
    border-bottom: 5px solid #ffffff;
  }
  .section .col,
  .section .col-auto {
    padding: 60px 4%;
  }
  .section .container .col,
  .section .container .col-auto {
    padding: 60px 30px;
  }
}
@media (max-width: 1200px) {
  .section .col,
  .section .col-auto {
    padding: 60px 30px;
  }
  .section .container .col,
  .section .container .col-auto {
    padding: 60px 30px;
  }
}
@media (max-width: 1024px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .section-header {
    padding-top: 110px;
    padding-bottom: 100px;
  }
}
@media (max-width: 900px) {
  .section .container .col,
  .section .container .col-auto {
    padding: 100px 30px;
  }
  .section-header {
    padding-top: 90px;
    padding-bottom: 80px;
  }
  .link-more,
  .link-more-white {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .col {
    padding-left: 20px;
    padding-right: 20px;
  }
  .row .col {
    flex-basis: unset;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section .container .col,
  .section .container .col-auto {
    padding: 100px 20px;
  }
  .section-header {
    padding-top: 90px;
    padding-bottom: 80px;
  }
}
/* end MEDIA */

/*ANIMATED SWITCH*/

.sliding-pages {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}
.page-slide-enter {
  transform: translateX(100%);
}
.page-slide-enter.page-slide-enter-active {
  transform: translateX(0);
  transition: transform 0.5s ease;
}
.page-slide-exit {
  transform: translateX(0);
}
.page-slide-exit.page-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}
.animated-switch {
  position: relative;
}
.animated-switchswitch-wrapper > div {
  position: absolute;
}
/*end ANIMATED SWITCH*/
