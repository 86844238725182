/*NAV MAIN*/
.nav-main-wrap {
  padding: 0 0 0 20px;
}
.nav-main-wrap .nav-main .reservation-button {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-family: regular;
  font-size: 14px;
}
.nav-main {
  margin: 0;
  padding: 0;
  list-style-type: none;
  letter-spacing: 0.5px;
  font-size: 0.6em;
  line-height: 1em;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.nav-main .item a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nav-main .item-hover a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nav-main,
.nav-main a {
  text-decoration: none;
  color: #ffffff;
}
.nav-main .item {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  margin: 0;
  text-transform: uppercase;
}
.nav-main .item-hover {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  margin: 0;
  text-transform: uppercase;
}

.nav-main .item-hover:hover .hover-menu {
  display: block;
}
.nav-main .item-hover .hover-menu {
  display: none;
  position: absolute;
  background-color: #ffffff;
  left: -35px;
  top: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.15);
}
.nav-main .item-hover .hover-menu p {
  color: #bb985f;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  padding: 8px 5px;
  cursor: pointer;
}
.nav-main .item-hover .hover-menu p:hover {
  background-color: #bb985f;
  color: white;
}
.nav-main .item-hover:before {
  display: none;
}
.nav-main .item:before {
  display: none;
}
.nav-main .active {
  border-bottom: 1px solid #ffffff;
}

@media (max-width: 1200px) {
  .nav-main {
    font-size: 16px;
    display: block;
  }
  .nav-main .item {
    padding: 0 18px;
  }
  .nav-main .item-hover {
    padding: 0 18px;
  }
  .nav-main .item-hover .hover-menu {
    left: -25px;
  }
  .nav-main .item-hover .hover-menu p {
    font-size: 12px;
  }
}
@media (max-width: 1100px) {
  .nav-main {
    font-size: 14px;
  }
  .nav-main .item {
    padding: 0 13px;
  }
  .nav-main .item-hover {
    padding: 0 13px;
  }
  .nav-main .item-hover .hover-menu p {
    font-size: 10px;
  }
}
@media (max-width: 920px) {
  .nav-main-wrap {
    padding: 0 0 0 10px;
    transform: translateX(15px);
  }
  .nav-main .item {
    padding: 0 10px;
  }
  .nav-main .item-hover {
    padding: 0 10px;
  }
}
@media (max-width: 900px) {
  .nav-main-wrap {
    padding: 0;
    transform: translateX(0);
  }
  .nav-main {
    font-size: 21px;
  }
  .nav-main-wrap .nav-main .reservation-button {
    color: white;
    background-color: #ba9255;
    width: 50%;
    margin: 0 auto;
  }
}
/*end NAV MAIN*/
