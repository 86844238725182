/*SPORT AND SPA*/
.sport-and-spa .section-header h1 {padding-bottom:90px;}
.section-sport-and-spa,
.section-sport-and-spa .col,
.section-sport-and-spa .col.col-auto  { padding-top:30px;padding-bottom: 30px;}
.section-sport-and-spa .col.col-auto {width:50%;}
.section-sport-and-spa .sport-box {position:relative;}
.section-sport-and-spa .sport-box .img {margin-bottom:25px; }
.section-sport-and-spa .sport-box img {display:block;width:100%;height:auto}
/* .section-sport-and-spa .sport-box img{height: 286.21px;} */
.section-sport-and-spa .sport-box h3 {margin:0;padding:10px 0;color:#ffffff;text-align:center;background-color:#C8A264}
@media(max-width:900px) {
  .sport-and-spa .section-header h1 {padding-bottom:45px;}
  .section-sport-and-spa {padding-top:30px; padding-bottom:0;}
  .section-sport-and-spa .col,
  .section-sport-and-spa .col.col-auto  { padding-top:0;padding-bottom: 30px;}
  .section-sport-and-spa .col.col-auto {width:100%;}
  .section-sport-and-spa.sport-box {padding-top:40px; background-color:#C8A264}
  .section-sport-and-spa .sport-box .img {margin-bottom:0; }
  .section-sport-and-spa .sport-box h3 {position: absolute;top:0;left:0;width:100%; font-size:20px;}
}
/*end SPORT AND SPA*/