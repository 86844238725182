/*GALLERY*/
.page-gallery .header {    background: #C8A264;}

.page-gallery .main .container {padding-top:150px; padding-bottom:150px;}

.gallery {padding-bottom:50px;}


.gallery-photos {
  display:-webkit-box; 
  display:-moz-box;  
  display:-ms-flexbox; 
  display:-webkit-flex; 
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-left:-15px;
  margin-right:-15px;
}
.gallery-photos > div {
  cursor:pointer;
  position: relative; padding:25% 0 0 0; text-align: center;
  -ms-flex-preferred-size:0;
  -ms-flex-positive:1;
  flex-grow:1;
  width:33.3333%; 
}
.gallery-photos > div > div {
  position: absolute; top:0; left:0; height:0; overflow: hidden; 
  height: 100%; width:100%; padding:15px;
}
.gallery-photos > div > div > div {
  width:100%; height:100%; overflow: hidden; 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.gallery-photos > div.landscape {width: 40%;}
.gallery-photos > div.portrait {width: 20%;}


.page-gallery .row.row-gallery-about {margin:0;}
.page-gallery .row.row-gallery-about .col {padding:0;}
.page-gallery .row-gallery-about .headline-box {position:relative; width:100%;  padding:30px 30px 30px 80px; background-color: #BF9860;color:#ffffff;}
.page-gallery .row-gallery-about .headline-box h2 {display:block; color: #ffffff; margin:0; padding-bottom:0;}
.page-gallery .row-gallery-about .headline-box .headline-up:before {border-color:#ffffff}
.page-gallery .row-gallery-about .col.col-info {padding-top:55px; padding-bottom:55px;}
.page-gallery .row-gallery-about .col.col-info p:last-child {padding-bottom: 0;}
.page-gallery .row-gallery-about .info-box {padding:55px 30px 0 80px;  }

.page-gallery .row-gallery-about .headline-box h2  {max-width: 560px;  width:100%; }
.page-gallery .row-gallery-about .info-box {max-width:670px; width:100%; }

@media (max-width:1200px) {
  .page-gallery .row-gallery-about .headline-box { padding:30px 30px 30px 30px; }
  .page-gallery .row-gallery-about .info-box {padding:55px 30px 0 30px;  }
}
@media (max-width:1024px) {
  .gallery-photos {margin-left:-10px; margin-right:-10px; }
  .gallery-photos > div > div { padding: 10px; }
  
  .page-gallery .row-gallery-about .col.col-info {padding-top:30px; padding-bottom:30px;}
  .page-gallery .row-gallery-about .info-box {padding:30px 30px 0 30px;  }
}
@media (max-width:900px) {
  .page-gallery .main .container {padding-top:150px; padding-bottom:50px;}
  .gallery-photos > div {padding: 28% 0 0 0; width:50%; }
}
@media (max-width:768px) {
  .gallery-photos {margin-left:-5px; margin-right:-5px; }
  .gallery-photos > div {padding: 50% 0 0 0; width:100%}
  .gallery-photos > div > div { padding: 5px; }

  .page-gallery .row.row-gallery-about .col {width:100%; }
  .page-gallery .row.row-gallery-about .col.col-img {order:2;height:340px;}
  .page-gallery .row.row-gallery-about .col.col-info {order:1}
  .page-gallery .row-gallery-about .headline-box h2,
  .page-gallery .row-gallery-about .info-box {max-width:100%; width:100%; }
}
/*end GALLERY*/