.reservation-banner-container {
  height: 70px;
  position: absolute;
  background-color: rgba(200, 162, 100, 0.9);
  width: 100%;
  z-index: 4;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}
.reservation-banner-container p {
  font-family: regular;
  font-size: 16px;
}

.reservation-banner-container p .hover {
  text-decoration: underline;
}

.reservation-banner-container .reserve-button {
  padding: 10px 20px;
  background-color: white;
  color: black;
  font-family: bold;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}
