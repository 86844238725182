/*NAV LANGS*/
.nav-langs {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.583em;
  line-height: 1em;
  transform: translateY(-3px);
}
.nav-langs,
.nav-langs a {
  text-decoration: none;
}
.nav-langs li {
  position: relative;
  display: inline-block;
  padding: 0 7px;
  color: #ffffff;
}
.nav-langs li:before {
  display: none;
}
.nav-langs .item a {
  color: #ffffff;
  font-weight: 700;
}
.nav-langs .item-selected {
  border: solid 2px white;
  margin: 0 5px;
  padding: 5px;
}
.nav-langs .active a {
  color: #c8a264;
}
.nav-langs .divider {
  padding-left: 3px;
  padding-right: 3px;
}

@media (max-width: 900px) {
  .nav-langs {
    font-size: 15px;
  }
}
/*end NAV LANGS*/
