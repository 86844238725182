/*FORM RESERVATION*/
.link-goto-reservation-form {
  z-index:999; position:fixed; top:250px; right:-48px; display:block; padding:10px 15px; 
  transform: rotate(-90deg);
  background:rgba(255, 255, 255, .9); 
  color:#C8A264;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border:1px solid #C8A264;
}
.link-goto-reservation-form a {color:#CAAB7B;}


.form-reservation-wrap.Close .container .row-2,
.form-reservation-wrap.Open .container .row-2   {
  -webkit-transition:all 0.4s ease-in-out;
  -moz-transition:all 0.4s ease-in-out;
  -ms-transition:all 0.4s ease-in-out;
  -o-transition:all 0.4s ease-in-out;
  transition:all 0.4s ease-in-out;
}
.form-reservation-wrap.Close .container .row-2 {max-height:0; overflow:hidden;}
.form-reservation-wrap.Open .container .row-2 {max-height:700px; height:auto; overflow:visible;}

.form-reservation-wrap {z-index:4; position:absolute; width:100%; bottom:0; left:0; right:0; background:rgba(200, 162, 100, .9); color:#ffffff;}
.form-reservation-wrap .container {flex-direction:column;}
.form-reservation-wrap .container .row-1 {padding-top:20px; padding-bottom:20px;}
.form-reservation-wrap .container .row-1 .col1 {text-align:left;}
.form-reservation-wrap .container .row-1 .col2 {text-align:center;} 
.form-reservation-wrap .container .row-1 h4 {margin-bottom:0; padding-bottom:0; color:#ffffff; font-size:1em; line-height:1em; cursor: pointer;}
.form-reservation-wrap .container .row-1 .link,
.form-reservation-wrap .container .row-1 .bttn-open-close {cursor:pointer;}

.form-reservation {
  display:flex;
  flex-basis:0; flex-grow:1;
  display:-webkit-box;
  display:-moz-box;
  display:-ms-flexbox;
  display:-webkit-flex;
  flex-wrap:wrap;
  margin-left:-7px;
  margin-right: -7px;
}
.form-reservation:nth-of-type(2) {padding-bottom:20px;}
.form-reservation .cell {
  -ms-flex-preferred-size:0;
  -ms-flex-positive:1; 
  width:20%;
  text-align:left; 
  padding:10px 7px;
}

.form-reservation label {color:#ffffff; display:block; padding-bottom:8px;}

.form-reservation .cell .input {width:100%;}
.form-reservation .cell.cell-bttn {
  display:-webkit-box; 
  display:-moz-box;  
  display:-ms-flexbox; 
  display:-webkit-flex; 
  display:flex;
  align-items: flex-end;
  justify-content: flex-end ;
}
.form-reservation .cell .bttn-submit {width:100%;}

.mobile-form-reservation,
.mobile-form-reservation-headline {display:none;}
.mobile-form-reservation-headline {color:#ffffff;text-align:center;}

@media (max-width:1024px) {
  .link-goto-reservation-form { top: 200px; right:-40px; font-size:18px; padding: 5px 13px; letter-spacing:1;}
  .form-reservation .cell {width:33.3333%;}
}
@media (max-width:900px) {
  .form-reservation-wrap .row {align-items:flex-start}
  .form-reservation-wrap .container .row-1 {padding-top:10px; padding-bottom:10px;}
  .form-reservation-wrap .container .row-1 .col2 {order: 1; text-align: left; padding-right:0;}
  .form-reservation-wrap .container .row-1 .col1 {order: 2; text-align: right; padding-left:0;}
  .form-reservation-wrap .container .row-1 .col3 {display:none;}

  .form-reservation-wrap .container .row-2 {padding-top:0; padding-bottom:0;}
}
@media (max-width:680px) {
  .form-reservation .cell {width:50%;}
  .form-reservation .cell:last-child {width:100%;}
}
@media (max-width:440px) {
  .desktop-form-reservation {display:none;}
  .mobile-form-reservation,
  .mobile-form-reservation-headline  {display:block;}
  .form-reservation-container {height:100%; width:100%; background:rgba(200, 162, 100, .9); padding:2em 30px;}
  
  .form-reservation .cell,
  .form-reservation .cell:last-child {width:100%;}
}
@media (max-width:410px) {
  .form-reservation-wrap .container .row-1 .col2 h4 ,
  .form-reservation-wrap .container .row-1 .col1 h4 {font-size:18px;}
}
@media (max-width:350px) {
  .form-reservation-wrap .container .row-1 .col2 h4 ,
  .form-reservation-wrap .container .row-1 .col1 h4 {font-size:16px;}
}
/*
.form-reservation input.input:-webkit-autofill,
.form-reservation input.input:-webkit-autofill:hover, 
.form-reservation input.input:-webkit-autofill:focus,
.form-reservation input.input:-internal-autofill-selected,
.form-reservation input.input:-internal-autofill-selected:focus {
  font-family:'Cormorant Infant', serif !important;
  font-size:18px !important; 
  line-height:1em !important;
  font-weight:400 !important; 
  border: 0 !important;
  -webkit-text-fill-color: #C8A264 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: transparent !important;  
}

'value-container':true,
'value-container--is-multi':isMulti,
'value-container--has-value':hasValue,
'control--is-disabled':isDisabled,
'control--is-focused':isFocused,
'control--menu-is-open':menuIsOpen,
'single-value':true,
'single-value--is-disabled':isDisabled,
.option--is-disabled  {}
.option--is-focused {}
.option--is-selected {}

/*end FORM RESERVATION*/
