/*HOME*/
.home .section-header {text-align:center; padding-bottom:120px;}
.home .section-header h1 {margin-bottom:0;padding-bottom:20px;color:#ffffff;}
.home .section-header h2 {margin-bottom:0;padding-bottom:0;color:#ffffff;}
.home .section h2 {color:#ffffff;}
.home .section .icons-list {padding-bottom: 30px;}
.home .section .icons-list span {padding-left: 15px; padding-right: 15px; font-size:1.6em; color:#ffffff}
.home .section .icons-list span:first-child {padding-left:0;}
.home .section .icons-list span:last-child {padding-right:0;}
.home .section .row {margin-left:0;margin-right:0;}
.home .section-accommodation p {max-width:460px;}

@media(max-width:1000px) {
    .home .section-accommodation,
    .home .section-restaurants,
    .home .section-sport {text-align: center;}
    .home .section-restaurants .row .col:nth-of-type(1),
    .home .section-restaurants .row .col:nth-of-type(2),
    .home .section-restaurants .row .col:nth-of-type(3) {width:33.3333%; height: 190px;}
    .home .section-restaurants .row .col-auto.col-30 {width:100%;} 
    .home .section-sport .row .col:nth-of-type(2),
    .home .section-sport .row .col:nth-of-type(3),
    .home .section-sport .row .col:nth-of-type(4) {width:33.3333%; height: 190px;}
    .home .section-sport .row .col-auto.col-30 {order:4; width:100%;} 
    .home .section-sport .row .col:nth-of-type(2) {order:1; }
    .home .section-sport .row .col:nth-of-type(3) {order:2; }
    .home .section-sport .row .col:nth-of-type(4) {order:3; }
    .home .section-gallery .row .col,
    .home .section-gallery .row .col-auto.col-30 {width:50%;}
}
@media(max-width:900px) {
    .home .section-header { padding-top:90px;  padding-bottom: 80px; }
    .home .section-header h1 {font-size:1.7em; display: none;}
    .home .section-header h2 {font-size:1.7em}
}
@media(max-width:680px) {
    .home .section {text-align:center}
    .home .link-more-white {width:100%;}
    .home .section-gallery .row {flex-direction: column;}
    .home .section-gallery .row .col,
    .home .section-gallery .row .col-auto.col-30 {width:100%;}
    .home .section-gallery .row .col {height:190px;}

}
/*end HOME*/
