/*ROOM TYPES*/

.accommodation .section-header .main-info {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.accommodation .section-header h1 {
  padding-bottom: 90px;
}
.accommodation .section-roomtypes h2 {
  color: #ffffff;
}

.section-roomtypes {
  color: #ffffff;
}
.section-roomtypes .col-auto {
  max-width: 600px;
  width: 100%;
  align-items: center;
}

.section-roomtypes .col-auto .flex-container {
  display: flex;
  flex-wrap: wrap;
}
.section-roomtypes .col-auto p {
  padding: 0;
  font-size: 19px;
}

.room-types .slider-gallery .slick-slider .slide {
  cursor: pointer;
}

.room-types .col-bg-spaces {
  padding: 35px 85px;
}
.room-types .col-spaces {
  padding: 35px 30px;
  position: relative;
  z-index: 2;
}
.room-types .col-spaces h3 {
  padding-bottom: 0;
}
.room-types .col-spaces-2 {
  padding: 0 30px;
}

.room-types .bg-full-left-headline {
  z-index: 0;
  position: relative;
  padding-left: 380px;
}
.room-types .bg-full-left-headline .page-headline {
  text-align: left;
}
.room-types .bg-full-left {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 100%;
  display: block;
  margin: 0 -350px 0 0;
  height: 200%;
  width: 50vw;
  background-color: rgba(133, 77, 39, 0.8);
}
.room-types .bg-headline h3 {
  position: relative;
  z-index: 2;
  padding-bottom: 35px;
}

.room-types .bg-full-right {
  z-index: 0;
  position: absolute;
  height: 100%;
  width: 50vw;
  top: 0;
  left: 0;
  background-color: rgba(133, 77, 39, 0.8);
}

.room-types .amenities {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}
.room-types .amenities span {
  text-align: center;
  font-size: 1.8em;
  padding-right: 30px;
}

.room-types .amenities-list {
  overflow: hidden;
  clear: both;
  padding-bottom: 15px;
  font-size: 0.916em;
  width: 120%;
}
.room-types .amenities-list ul {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
.room-types .amenities-list ul li {
  position: relative;
  padding-bottom: 20px;
  padding-left: 30px;
  line-height: 1.2em;
}
.room-types .amenities-list ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e909";
  font-family: "icomoon" !important;
}
.room-types .amenities-list ul:nth-child(1) {
  width: 35%;
}
.room-types .amenities-list ul:nth-child(2) {
  width: 65%;
}

.row-room-types-list {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
.row-room-types-list .col {
  width: 50%;
  padding-bottom: 35px;
  flex-basis: unset;
}
.row-room-types-list .thumb {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.row-room-types-list .thumb:hover::before {
  opacity: 1;
}
.row-room-types-list .thumb:before {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(167, 144, 106, 0);
  background: -moz-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(62, 55, 44, 0)),
    color-stop(100%, rgba(62, 55, 44, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(62, 55, 44, 0) 0%,
    rgba(62, 55, 44, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7906a', endColorstr='#a7906a', GradientType=0 );
}
.row-room-types-list .title {
  z-index: 2;
  position: absolute;
  display: block;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 1.4583em;
  line-height: 1.1em;
  font-weight: 600;
}
.row-room-types-list .thumb img {
  display: block;
  width: 100%;
  height: auto;
}
.row-room-types-list p {
  padding-bottom: 20px;
}
.row-room-types-list .link-more {
  width: 100%;
  display: block;
}
#margin {
  margin-top: 20px;
}
.room-types.studio .col-room-types-list-studio,
.room-types.double-room .col-room-types-list-double-room,
.room-types.double-room-sea .col-room-types-list-double-room-sea,
.room-types.apartment-double .col-room-types-list-apartment-double,
.room-types.apartment .col-room-types-list-apartment {
  display: none;
}

@media (max-width: 1440px) {
  .section-roomtypes {
    height: 100vh;
  }
}
@media (max-width: 1200px) {
  .accommodation .section-header h1 {
    padding-bottom: 45px;
  }
}
@media (max-width: 1000px) {
  .room-types .bg-headline h3 {
    padding-bottom: 25px;
  }
  .room-types .col-bg-spaces {
    padding: 25px;
  }
  .room-types .col-spaces {
    padding: 25px 0;
  }
  .room-types .bg-full-left {
    margin: 0 -350px 0 0;
    height: 185%;
  }
  .room-types .cols-to-right .col-auto.col-45 {
    width: 48%;
  }
  .room-types .amenities-list,
  .room-types .amenities-list ul:nth-child(1),
  .room-types .amenities-list ul:nth-child(2) {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .accommodation .section {
    text-align: center;
  }
  .accommodation .section .row,
  .accommodation .section .col-auto {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
  }
  .accommodation .overlay-gradient-1:before,
  .accommodation .overlay-gradient-3:before {
    opacity: 0.65;
    background: rgb(133, 106, 61);
    background: -moz-linear-gradient(
      45deg,
      rgba(133, 106, 61, 1) 0%,
      rgba(67, 53, 31, 1) 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      rgba(133, 106, 61, 1) 0%,
      rgba(67, 53, 31, 1) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(133, 106, 61, 1) 0%,
      rgba(67, 53, 31, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#856a3d', endColorstr='#43351f',GradientType=1 );
  }

  .room-types .bg-full-left,
  .room-types .bg-full-left-headline {
    display: none;
  }
  .room-types .row-1 .col-auto.col-45 {
    order: 1;
    width: 100%;
  }
  .room-types .row-1 .col-auto.col-50 {
    order: 2;
    width: 100%;
  }
  .room-types .row-1 .col-spaces {
    padding: 0 0 30px 0;
  }
  .room-types .row-1 .bg-headline {
    margin: -25px -20px 0 -20px;
    padding: 25px 20px 0 20px;
    background-color: #bf9860;
  }
  .room-types .col-bg-spaces {
    padding: 25px 0;
  }

  .room-types .amenities-list ul:nth-child(1) {
    width: 35%;
  }
  .room-types .amenities-list ul:nth-child(2) {
    width: 65%;
  }
  .room-types .row.cols-to-right {
    justify-content: flex-start;
    background-color: #bf9860;
  }
  .room-types .row.cols-to-right .col-auto.col-45 {
    width: 100%;
  }
  .room-types .row.cols-to-right .col-auto.col-45 h3.align-center {
    text-align: left;
  }
  .room-types .row.cols-to-right .bg-full-right {
    display: none;
  }

  .room-types .row-2 h4 {
    padding-bottom: 10px;
  }
  .room-types .row-2 .col:nth-of-type(1) {
    padding-bottom: 25px;
  }
}
@media (max-width: 768px) {
  .room-types .m-align-center {
    text-align: center;
  }
  .room-types .m-align-center h3 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .row-room-types-list .col {
    width: 100%;
    position: relative;
  }
  .row-room-types-list .thumb {
    margin: 0;
  }
  .row-room-types-list .thumb:before {
    opacity: 1;
  }
  .row-room-types-list p {
    display: none;
  }
  .row-room-types-list .link-more {
    display: block;
    position: absolute;
    top: 0;
    right: 20px;
    width: auto;
  }
}

/*ANIMATED SWITCH*/

.sliding-pages {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}
.page-slide-enter {
  transform: translateX(100%);
}
.page-slide-enter.page-slide-enter-active {
  transform: translateX(0);
  transition: transform 0.5s ease;
}
.page-slide-exit {
  transform: translateX(0);
}
.page-slide-exit.page-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.animated-switch {
  position: relative;
}
.animated-switchswitch-wrapper > div {
  position: absolute;
}
/*end ANIMATED SWITCH*/
