/*SPORT AND SPA*/
.sport-and-spa .section-header h1 {
  padding-bottom: 90px;
}
.section-sport-and-spa,
.section-sport-and-spa .col,
.section-sport-and-spa .col.col-auto {
  padding-top: 30px;
  padding-bottom: 30px;
}
.section-sport-and-spa .col.col-auto {
  width: 50%;
}
.section-sport-and-spa .sport-box {
  position: relative;
}
.section-sport-and-spa .sport-box .img {
  margin-bottom: 25px;
}
.section-sport-and-spa .sport-box img {
  display: block;
  width: 100%;
  height: auto;
}
/* .section-sport-and-spa .sport-box img{height: 286.21px;} */
.section-sport-and-spa .sport-box h3 {
  margin: 0;
  padding: 10px 0;
  color: #ffffff;
  text-align: center;
  background-color: #c8a264;
}
.section-container {
  margin: 40px auto;
  width: 1220px;
  display: flex;
  justify-content: space-between;
}

.text-container {
  width: 43%;
  background-color: #c8a264;
  padding: 140px 0;
  color: white;
}
.text-banner {
  background-color: #bf9860;
  padding: 50px 20px;
  font-size: 46px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px 3px 6px 0px rgba(109, 97, 88, 0.6);
}
.text-container p {
  padding: 100px 50px 50px 50px;
  font-size: 30px;
}

.event-image-2 {
  width: 55%;
  height: 800px;
  background-image: url("../../imgs/pacho_i_pepo_2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.event-image {
  width: 55%;
  height: 800px;
  background-image: url("../../imgs/event-image-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 900px) {
  .section-container {
    flex-direction: column;
    width: 95%;
  }
  .section-container .text-container {
    width: 100%;
    height: 500px;
    padding: 50px 0;
    text-align: center;
  }
  .section-container .event-image {
    width: 100%;
  }
  .sport-and-spa .section-header h1 {
    padding-bottom: 45px;
  }
  .section-sport-and-spa {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .section-sport-and-spa .col,
  .section-sport-and-spa .col.col-auto {
    padding-top: 0;
    padding-bottom: 30px;
  }
  .section-sport-and-spa .col.col-auto {
    width: 100%;
  }
  .section-sport-and-spa.sport-box {
    padding-top: 40px;
    background-color: #c8a264;
  }
  .section-sport-and-spa .sport-box .img {
    margin-bottom: 0;
  }
  .section-sport-and-spa .sport-box h3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 20px;
  }
}

/*end SPORT AND SPA*/
