/*ABOUT US*/
.about-us .section {border:0;}
.about-us .section .row {margin:0;}
.about-us .section .col.col-map,
.about-us .section .col.col-form-contact-us {padding:0;background-color:#C8A264; }
.about-us .form-contact-us-box {max-width:600px; margin:auto; padding:60px 30px; color:#ffffff;}
.about-us .form-contact-us-box .headline-up:before {border-color:#ffffff}
.about-us .form-contact-us-box h2 {padding-bottom: 50px;color:#ffffff}
@media(max-width:900px) {
    .about-us .form-contact-us-box {padding:30px 20px;}
    .about-us .section .col {flex-basis:unset; width:100%;}
    .about-us .section .col.col-img,
    .about-us .section .col.col-map {order:2; height:320px;}
    .about-us .section .col.col-info,
    .about-us .section .col.col-form-contact-us {order:1;}
}
/*end ABOUT US*/