/*RESTAURANTS AND BARS*/

.restaurants-and-bars .section-header h1 {padding-bottom:90px;}
.restaurants-and-bars .section-header .main-info {max-width: 960px; margin-left:auto; margin-right:auto; }

.restaurants-and-bars .main {padding-top:0;padding-bottom:20px;}
.restaurants-and-bars .main section {border-top:20px solid #ffffff;}
.restaurants-and-bars .main section .row {margin:0 -10px; overflow: hidden;}
.restaurants-and-bars .main section .row .col {border-left:10px solid #ffffff;border-right:10px solid #ffffff;}
.restaurants-and-bars .main section .col-info {
    width:33.3333%; padding:140px 0; 
    background-color:rgba(200,162,100,0.8);
    color:#ffffff; 
}
.restaurants-and-bars .main section .col-info h2 {font-style: italic;}
.restaurants-and-bars .main section .col-info p {padding:0 50px;margin:0;}

.restaurants-and-bars .main section .headline-box {
    margin-bottom:45px; background-color:#BF9860; padding:20px 50px; 
    -webkit-box-shadow: 0px 3px 6px 0px rgba(109,97,88,0.6);
    -moz-box-shadow: 0px 3px 6px 0px rgba(109,97,88,0.6);
    box-shadow: 0px 3px 6px 0px rgba(109,97,88,0.6);
}
.restaurants-and-bars .main section .headline-box h2,
.restaurants-and-bars .main section .headline-box h3 {color:#ffffff;margin-bottom: 0;padding-bottom:0;}

.restaurants-and-bars .main section .col-img {width:66.6666%;background-repeat: no-repeat; background-position: center;background-size:cover;}

@media(max-width:1200px) {
    .restaurants-and-bars .main {padding-top:0;padding-bottom:10px;}
    .restaurants-and-bars .main section {border-top:10px solid #ffffff;}
    .restaurants-and-bars .main section .row {margin-left:-5px; margin-right: -5px;}
    .restaurants-and-bars .main section .row .col {border-left:5px solid #ffffff;border-right:5px solid #ffffff;}
    .restaurants-and-bars .main section .col-info {padding:70px 0;}
    .restaurants-and-bars .main section .col-info p {padding:0 30px;}
    .restaurants-and-bars .main section .headline-box {padding:15px 30px;}
}
@media(max-width:1024px) {
    .restaurants-and-bars .main section .col-info,
    .restaurants-and-bars .main section .col-img {width:50%;}
}
@media(max-width:768px) {
    .restaurants-and-bars .main section .row {margin:0;}
    .restaurants-and-bars .main section .row .col {border:0}
    .restaurants-and-bars .main section .col-info,
    .restaurants-and-bars .main section .col-img {width:100%;}
    .restaurants-and-bars .main section .col-info {order:1; padding:0 0 30px 0; }
    .restaurants-and-bars .main section .col-img {order:2; height:400px;}
    .restaurants-and-bars .main section .headline-box {margin-bottom:30px}
}
/*end RESTAURANTS AND BARS*/



