.video-bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.video-background {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* pointer-events: none; */
}


@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 300%;
        top: -100%;
    }
}
@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}
