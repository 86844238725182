
html, body {margin:0; padding:0; background-color:#ffffff; min-height:100%;}
body {
  font-family:'Cormorant Infant','Times New Roman',Times, serif; 
  font-weight:400; font-size:24px; color:#6D6158;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -o-box-sizing:border-box;
  box-sizing:border-box;
  outline:none;
}
.flex {
  display:-webkit-box; 
  display:-moz-box;  
  display:-ms-flexbox; 
  display:-webkit-flex; 
  display:flex; 
  height:100%;
}
.animation {
  -webkit-transition:all 0.3s ease-in-out;
  -moz-transition:all 0.3s ease-in-out;
  -ms-transition:all 0.3s ease-in-out;
  -o-transition:all 0.3s ease-in-out;
  transition:all 0.3s ease-in-out;
}

section,
h1, h2, h3, h4, h5, h6,
p, ul, ol, li {margin:0;padding:0;}
ul li {list-style:none;}
ul li:before {content:"\00B7"; padding-right:10px;}
ol {list-style-position:inside; }

p {line-height:1.33em}

a {color:#C8A264; text-decoration:none;}
a:hover {text-decoration:none;}
.pointer {cursor:pointer;}
img {max-width:100%; height:auto; display:inline-block;}

b, strong, .bold {font-weight:700;}

h1 {font-size:2.9166em; line-height:1em; font-weight:700;} /*70px*/
h2 {font-size:2.083em;  line-height:1em; font-weight:700; color:#C8A264; padding-bottom:35px;  } /*50px*/
h3 {font-size:1.4583em; line-height:1.1em; color: #C8A264; padding-bottom:15px; } /* 35px /24px */
h4 {font-size:1.25em; font-weight:700; padding-bottom:35px; }
h4 .icon {padding-right:30px; font-size:1.3em; vertical-align: middle}
h5 {font-size:1em;}
h6 {font-size:0.75em;}

.home-headline {font-size:1.9583em; font-weight:700;} /*47px*/
.page-headline {font-size:2.9166em; text-align: center; margin:0 auto; padding:0 0 35px 0;}

.headline-bg {background-color:rgba(200,162,100,.8)}

.headline-up {position: relative; display:inline-block}
.headline-up:before {content:" "; position:absolute; top:-10px; left:0; width:130px; border-top:1px solid #C8A264; height:1px;overflow:hidden; }

/*
70px /24px - font-size:2.9166em
71
68

47px /24px - font-size:1.9583em; Homepage main headline

50px /24px - font-size:2.083em;
35px /24px - font-size:1.4583em
30px /24px - font-size:1.25em;
*/

.txt-right {text-align:right; }
.txt-center {text-align:center; }

.align-center {text-align:center}

.align-right {
  display:-webkit-box; 
  display:-moz-box;  
  display:-ms-flexbox; 
  display:-webkit-flex; 
  display:flex; 
  align-items: flex-end; flex-direction: column;
}

.align-top {align-items:flex-start}
.align-middle {align-items:center}
.align-bottom {align-items:flex-end}


.txt-light {font-weight:300;}
.txt-regular {font-weight:400;}
.txt-medium {font-weight:500;}
.txt-semibold {font-weight:600;}

.font-size-sm {font-size:0.75em;}

.txt-bold {font-weight:700;}
.italic {font-style: italic; }

.txt-color {color:#6D6158}
.color-white {color:#ffffff}
.color-1 {color:#C8A264}

.relative {position: relative;}
.bg-1 {background-color:#C8A264; color:#ffffff;}
.bg-2 {background-color:rgba(200,162,100,.8); color: #ffffff; }

.no-spaces {margin-top:0 !important; margin-bottom:0 !important; padding-top:0 !important; padding-bottom:0 !important;}
.no-space-right {margin-right:0 !important; padding-right:0 !important;}

.z-index-0 {z-index:0}
.z-index-1 {z-index:1}
.z-index-2 {z-index:2}

form {margin:0;padding:0}
button {cursor: pointer;}
section {position:relative; margin:0;padding:0;}

::-webkit-input-placeholder {font-size:19px; color:rgba(200,162,100, 1); font-family:'Cormorant Infant','Times New Roman',Times, serif; font-weight:400; }
::-moz-placeholder {font-size:19px; color:rgba(200,162,100, 1); font-family:'Cormorant Infant','Times New Roman',Times, serif; font-weight:400; }
:-ms-input-placeholder {font-size:19px; color:rgba(200,162,100, 1); font-family:'Cormorant Infant','Times New Roman',Times, serif; font-weight:400; }
:-moz-placeholder {font-size:19px; color:rgba(200,162,100, 1); font-family:'Cormorant Infant','Times New Roman',Times, serif; font-weight:400; }

button, input, select, textarea {
  margin:0; padding-left:15px; padding-right:15px;
  background-color:#ffffff; border:1px solid #ffffff;
  font-size:19px; color:#C8A264;
  font-family:'Cormorant Infant','Times New Roman',Times, serif; 
  font-weight:400;
}
textarea {padding-top:10px; padding-bottom:10px;}
input, select, button {height:50px;}
button,
.bttn-submit {
  background-color: transparent; color:#ffffff;
  -webkit-transition:all 0.3s ease-in-out;
  -moz-transition:all 0.3s ease-in-out;
  -ms-transition:all 0.3s ease-in-out;
  -o-transition:all 0.3s ease-in-out;
  transition:all 0.3s ease-in-out;
}
.bttn-submit {padding-left:5px; padding-right:5px;}
/* button:hover, */
.bttn-submit:hover {background-color: #ffffff; color:#C8A264}

/* DATEPICKER */
.form-reservation .react-datepicker-wrapper,
.form-reservation .react-datepicker__input-container { width:100%;}
.form-reservation .react-datepicker__input-container .datepicker-input { height:50px; width:100%; padding:13px 15px; font-size:19px; color:rgba(200,162,100, 1); background-color:#ffffff }
.form-reservation .react-datepicker__input-container .datepicker-input:after {position:absolute; display:block; right:15px; top:13px; content: "\e908";font-family: 'icomoon' !important;}
/*end DATEPICKER */

/* SELECT */
.form-reservation .select * {font-size:19px; color: rgba(200,162,100, 1);}
.form-reservation .select > div:nth-of-type(1) {background-color:#ffffff; padding:0;}
.form-reservation .select > div:nth-of-type(1) > div:nth-of-type(1) {padding:0;}
.form-reservation .select > div:nth-of-type(1) > div:nth-of-type(1) >div {margin:0; padding-left:15px; padding-right:15px;}
.form-reservation .select > div:nth-of-type(1) > div:nth-of-type(2) {position: absolute; right:0; top:8px; } 
.form-reservation .select > div:nth-of-type(1) > div:nth-of-type(2) > span:nth-of-type(1) {display:none;}
.form-reservation .select > div:nth-of-type(2) {border:0;}
.form-reservation .select > div:nth-of-type(2) > div> div {background-color:#ffffff; padding:5px 15px; }
.form-reservation .select > div:nth-of-type(2) > div> div:hover {background-color:rgba(200,162,100, 1);color:#ffffff;}
.form-reservation .select > div:nth-of-type(2) > div> div.active {border:1px solid lime;}
/*end SELECT */

/*  #C8A264   rgba(104, 81,85,1)  */

/* MEDIA */
@media(max-width:1200px) {
}
@media(max-width:1024px) {
  body {font-size:21px;}
}
@media(max-width:900px) {
  body {font-size:18px;}
  .headline-up:before {width:80px;}

}
/* end MEDIA */


/*
.line-bottom-center {text-align:center;}
.line-bottom-center:after {content:" "; display:block; width:50px; border-top:2px solid #DFC475; margin-top:15px; margin-left:auto; margin-right:auto}

.space-top-xs {padding-top:15px;}
.space-top-sm {padding-top:35px;}
.space-top-md {padding-top:60px;}
.space-top-lg {padding-top:125px;}
.space-top-xl {padding-top:150px;}

.space-bottom-xs {padding-bottom:15px;}
.space-bottom-sm {padding-bottom:35px;}
.space-bottom-md {padding-bottom:60px;}
.space-bottom-lg {padding-bottom:125px;}
.space-bottom-xl {padding-bottom:150px;}

.no-paddings {padding:0 !important;}
.no-paddings-left-right {padding-left:0 !important;padding-right:0 !important;}

.font-size-lg {font-size:5em;  } /* font size 100px/20px *
.font-size-xl {font-size:4.4em;  } /* font size 88px/20px *
.font-size-md {font-size:2.9em;  } /* font size 58px/20 *
.font-size-sm {font-size:1.4em; } /* font size 28px/20 *
.font-size-xs {font-size:1em; } /* font size 20px/20 

*/